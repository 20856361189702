.icon-angle-down {
	width: 17px;
	height: 10px;
}

.icon-angle-right {
	width: 20px;
	height: 33px;
}

.icon-cross {
	width: 14px;
	height: 14px;
}

.icon-cross-2 {
	width: 13px;
	height: 13px;
}

.icon-facebook {
	width: 10px;
	height: 18px;
}

.icon-grid {
	width: 999.86px;
	height: 714.17px;
}

.icon-linkedin {
	width: 979.72px;
	height: 936.46px;
}

.icon-list {
	width: 999.35px;
	height: 714.17px;
}

.icon-marker {
	width: 571.43px;
	height: 857.14px;
}

.icon-menu {
	width: 1000.5px;
	height: 625px;
}

.icon-plus {
	width: 983.33px;
	height: 983.33px;
}

.icon-plus-2 {
	width: 15px;
	height: 15px;
}

.icon-search {
	width: 22px;
	height: 22px;
}

.icon-user {
	width: 799.19px;
	height: 949.61px;
}

